import React from "react";
import { Link } from "react-router-dom";
import { usePrefixContext } from "../../../context/PrefixContext";

import img_FavAdd from "../../../Images/FavAdd.gif";

export default function LeagueTitle(props) {
  const { liveTB, pt, dataLg, SportsSelect, SportsPriceSelect, onFavAll } =
    props;
  const prefixContext = usePrefixContext();
  var zone_show = {};
  zone_show["CN"] = dataLg.zone_cn;
  zone_show["EN"] = dataLg.zone_en;
  zone_show["ES"] = dataLg.zone_en;
  zone_show["FR"] = dataLg.zone_en;
  zone_show["IN"] = dataLg.zone_en;
  zone_show["JP"] = dataLg.zone_en;
  zone_show["KH"] = dataLg.zone_en;
  zone_show["KR"] = dataLg.zone_en;
  zone_show["LA"] = dataLg.zone_en;
  zone_show["MM"] = dataLg.zone_en;
  zone_show["PT"] = dataLg.zone_en;
  zone_show["TH"] = dataLg.zone_th;
  zone_show["YN"] = dataLg.zone_en;
  //var zone_show = (dBall[29]==="" ? dBall[26] : dBall[29]);

  return (
    <div style={{ display: "table-row" }} className="Event">
      {SportsSelect === 1 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "720px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
        </>
      ) : SportsSelect === 7 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "720px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
        </>
      ) : SportsPriceSelect === 2 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "720px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
        </>
      ) : SportsPriceSelect === 3 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "720px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
        </>
      ) : SportsPriceSelect === 4 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "720px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
        </>
      ) : pt === 0 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "700px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              textAlign: "right",
              verticalAlign: "middle",
            }}
          >
            <Link
              to="/"
              style={{ paddingBottom: "3px", paddingRight: "3px" }}
              onClick={onFavAll}
            >
              <img
                title="Add All"
                src={img_FavAdd}
                border="0"
                align="absmiddle"
                alt=""
              />
            </Link>
          </div>
        </>
      ) : pt === 1 ? (
        <>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "20px",
              paddingLeft: "5px",
              width: "*",
            }}
          >
            <div style={{ width: "1px" }}>
              <div
                style={{
                  position: "relative",
                  width: "700px",
                  overflow: "hidden",
                }}
              >
                {zone_show[prefixContext.getCurrentLanguage()]}
              </div>
            </div>
          </div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          {liveTB === 1 ? (
            ""
          ) : (
            <div style={{ display: "table-cell" }}>&nbsp;</div>
          )}
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div style={{ display: "table-cell" }}>&nbsp;</div>
          <div
            style={{
              display: "table-cell",
              textAlign: "right",
              verticalAlign: "middle",
            }}
          >
            <Link
              to="/"
              style={{ paddingBottom: "3px", paddingRight: "3px" }}
              onClick={onFavAll}
            >
              <img
                title="Add All"
                src={img_FavAdd}
                border="0"
                align="absmiddle"
                alt=""
              />
            </Link>
          </div>
        </>
      ) : pt === 2 ? (
        ""
      ) : (
        ""
      )}
    </div>
  );
}
